import { mixins } from "@/plugins/mixins";
export default {
  name: "customerBalanceRefund",
  mixins: [mixins],
  data() {
    return {
      columns: [{
        type: 'expand',
        typeSlot: 'logExpand'
      }, {
        label: '用户',
        prop: 'customerName'
      }, {
        label: '提交时间',
        prop: 'createTime',
        width: '170px'
      }, {
        label: '退款金额',
        prop: 'refundFee'
      }, {
        label: '实际退款金额',
        prop: 'realRefundFee'
      }, {
        label: '处理状态',
        prop: 'refundState'
      }],
      topButtons: [],
      linkButtons: [],
      // 搜索字段
      searchItems: [{
        prop: 'time',
        propItems: ['beginTime', 'endTime'],
        label: '时间范围',
        type: 'date',
        dateType: 'daterange',
        valueFormat: 'YYYY-MM-DD',
        defaultValue: [new Date().format('yyyy-MM-dd'), new Date().format('yyyy-MM-dd')],
        shortcuts: this.defaultDateShortcuts()
      }]
    };
  },
  methods: {
    loadLog(item) {
      if (!item.id) {
        return;
      }
      this.$api.business.customerBalanceRefund.getLogs({
        refundId: item.id
      }).then(res => {
        if (res.code === 200) {
          item.items = res.data;
        }
      });
      return '加载中';
    }
  }
};